var columns = [{
  title: '收益用户ID',
  dataIndex: '',
  key: 'benefituid',
  width: '140px',
  scopedSlots: {
    customRender: 'benefituid'
  }
}, {
  title: '收益用户昵称',
  dataIndex: 'benefitnickname',
  key: 'benefitnickname',
  width: '100px',
  scopedSlots: {
    customRender: 'benefitnickname'
  }
}, {
  title: '收益金额',
  dataIndex: 'benefitamount',
  key: 'benefitamount',
  width: '120px',
  scopedSlots: {
    customRender: 'benefitamount'
  }
}, {
  title: '收益类型',
  dataIndex: 'consumetype',
  key: 'consumetype',
  width: '120px',
  scopedSlots: {
    customRender: 'consumetype'
  }
}, {
  title: '付费方ID',
  dataIndex: 'consumeuid',
  key: 'consumeuid',
  width: '140px',
  scopedSlots: {
    customRender: 'consumeuid'
  }
}, {
  title: '付费方昵称',
  dataIndex: 'consumenickname',
  key: 'consumenickname',
  width: '100px',
  scopedSlots: {
    customRender: 'consumenickname'
  }
}, {
  title: '付费金额',
  dataIndex: 'consumeamount',
  key: 'consumeamount',
  width: '120px',
  scopedSlots: {
    customRender: 'consumeamount'
  }
}, {
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '160px',
  scopedSlots: {
    customRender: 'createtime'
  }
}];
export { columns };